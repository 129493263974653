import {
  useCameras,
  useCreateScanEscalation,
  useVisonHosts,
} from '@hakimo-ui/hakimo/data-access';
import { Camera } from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert, Selectable } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import LiveFeeds from '../components/LiveFeeds';
import { ScanMode } from '../types/common';
import { EventsHistory } from './events-history/EventsHistory';
import ScanSOP from './scan-sop/ScanSOP';
import { getQueryParams } from './utils';

interface Props {
  visionTenants: Selectable[];
}

export function Monitoring(props: Props) {
  const { visionTenants } = props;
  const [scanMode, setScanMode] = useState<ScanMode>(ScanMode.MONITORING);
  const [escalationId, setEscalationId] = useState('');
  const [locationIdForEscalation, setLocationIdForEscalation] = useState('');
  const [escalatedCamId, setEscalatedCamId] = useState('');
  const { data: tenantVisionHostMap } = useVisonHosts(
    getQueryParams(visionTenants, 'tenantIds')
  );
  const { data: allCamerasDto } = useCameras(
    getQueryParams(visionTenants, 'tenants')
  );
  const {
    data,
    isSuccess,
    mutate: createEscalation,
    isError,
    error,
  } = useCreateScanEscalation();

  useEffect(() => {
    if (isSuccess) {
      data && setEscalationId(data.escalation_id);
      toast('Incident escalated successfully');
    }
  }, [data, isSuccess]);

  const handleEscalation = (locationId: string, camId: string) => {
    setScanMode(ScanMode.ESCALATION);
    createEscalation({
      camera_id: camId,
      location_id: locationId,
    });
    setLocationIdForEscalation(locationId);
    setEscalatedCamId(camId);
  };
  const changeModeToMonitoring = () => {
    setScanMode(ScanMode.MONITORING);
    setLocationIdForEscalation('');
  };
  const allVisionHosts: string[] = [];
  tenantVisionHostMap &&
    Object.values(tenantVisionHostMap).reduce((acc, hosts) => {
      hosts.forEach((visionHost) => acc.push(visionHost.host));
      return acc;
    }, allVisionHosts);

  const tenantCamerasMap = useMemo(() => {
    const allCams = allCamerasDto?.items || [];
    const map: Record<string, Camera[]> = {};
    visionTenants.forEach((tenant) => {
      map[tenant.id] = allCams.filter((cam) => cam.tenantId === tenant.id);
    });
    return map;
  }, [allCamerasDto?.items, visionTenants]);
  const allTenantCount = visionTenants.length;
  return (
    <>
      {isError && (
        <Alert className="m-4" type="error">
          {error.message}
        </Alert>
      )}
      <div className="flex gap-1">
        <div
          className={clsx(`grid flex-1 grid-cols-${allTenantCount} p-4' gap-4`)}
        >
          {tenantVisionHostMap &&
            allCamerasDto &&
            visionTenants.map((tenant, i) => (
              <div key={tenant.id} className="flex-1">
                <div className="mb-1 border-b p-2 text-center font-bold">
                  {tenant?.name || `Tenant ${i + 1}`}
                </div>
                <LiveFeeds
                  tenantId={tenant.id}
                  visionHosts={tenantVisionHostMap[tenant.id]}
                  cameras={tenantCamerasMap[tenant.id]}
                  scanMode={scanMode}
                  onEscalate={handleEscalation}
                  escalationCamId={escalatedCamId}
                />
              </div>
            ))}
        </div>
        {scanMode === ScanMode.ESCALATION && (
          <div className="w-1/4">
            <ScanSOP
              onResolveEscalation={changeModeToMonitoring}
              escalationId={escalationId}
              locationId={locationIdForEscalation}
              escalatedCamId={escalatedCamId}
              onCancel={changeModeToMonitoring}
            />
          </div>
        )}
        {scanMode !== ScanMode.ESCALATION && (
          <div className="w-/4">
            <EventsHistory visionHosts={allVisionHosts} />
          </div>
        )}
      </div>
    </>
  );
}
