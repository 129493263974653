import { trackEvent } from '@hakimo-ui/hakimo/util';
import { useCallback } from 'react';
import { useCameras } from '../hooks/useCameras';

import { Camera, VisionHost } from '@hakimo-ui/hakimo/types';
import { ScanMode } from '../types/common';
import LiveCameraFeed from './LiveCameraFeed';

interface Props {
  tenantId: string;
  visionHosts: VisionHost[];
  scanMode: ScanMode;
  cameras: Camera[];
  onEscalate: (locationId: string, camId: string) => void;
  escalationCamId: string;
}

const LiveFeeds = (props: Props) => {
  const {
    tenantId,
    visionHosts,
    onEscalate,
    cameras,
    scanMode,
    escalationCamId,
  } = props;

  const onNewEvent = useCallback(
    (cameraId: string) => {
      trackEvent('event_received', {
        cameraId,
        tenantId,
      });
    },
    [tenantId]
  );

  const [state, removeCamera, snoozeCamera] = useCameras(
    visionHosts,
    cameras,
    onNewEvent
  );
  const { cameraViewPropertiesMap, mostFrequentIds } = state;

  const onClickSafe = (cameraId: string) => {
    trackEvent('event_marked_safe', {
      cameraId,
      tenantId,
    });
    removeCamera(cameraId);
  };

  const onClickSnooze = (cameraId: string) => {
    trackEvent('event_snoozed', {
      cameraId,
      tenantId,
    });
    snoozeCamera(cameraId);
  };

  const onClickEscalate = (locationId: string, cameraId: string) => {
    trackEvent('event_escalated', {
      cameraId,
      tenantId,
    });
    onEscalate(locationId, cameraId);
  };

  return (
    <div>
      <div className="flex flex-wrap gap-2">
        {Object.entries(cameraViewPropertiesMap).length > 0 &&
          cameras.map((camera: Camera, index: number) => (
            <div
              key={`${camera.id}-${index}`}
              className={`w-[320px] grow`}
              style={{ order: cameraViewPropertiesMap[camera.id]?.order }}
            >
              <LiveCameraFeed
                cameraName={camera.name}
                livestreamUrl={camera.livestreamUrl || ''}
                latestEventTime={
                  cameraViewPropertiesMap[camera.id].latestEventTime
                }
                hideActions={
                  scanMode === ScanMode.ESCALATION &&
                  camera.id === escalationCamId
                }
                state={
                  cameraViewPropertiesMap[camera.id].isVisible
                    ? 'active'
                    : mostFrequentIds.has(camera.id)
                    ? 'active-hidden'
                    : 'inactive'
                }
                onClickSafe={() => onClickSafe(camera.id)}
                onClickSnooze={() => onClickSnooze(camera.id)}
                onClickEscalate={() =>
                  onClickEscalate(String(camera.location.id), camera.id)
                }
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default LiveFeeds;
