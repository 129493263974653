import { WebrtcPlayer } from '@hakimo-ui/hakimo/ui-elements';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { ReverseProgressRef } from './ReverseProgress';

type Props = {
  cameraName: string;
  livestreamUrl: string;
  latestEventTime: number;
  hideActions: boolean;
  state: 'active' | 'active-hidden' | 'inactive';
  onClickSafe: () => void;
  onClickSnooze: () => void;
  onClickEscalate: () => void;
};

const LiveCameraFeed = (props: Props) => {
  const {
    cameraName,
    livestreamUrl,
    latestEventTime,
    hideActions,
    state,
    onClickSafe,
    onClickSnooze,
    onClickEscalate,
  } = props;
  const progressRef = useRef<ReverseProgressRef>(null);

  useEffect(() => {
    if (progressRef.current) {
      progressRef.current.reset();
    }
  }, [latestEventTime]);

  if (state === 'inactive') {
    return <div className="aspect-video"></div>;
  }

  return (
    <div
      className={clsx('group/feed relative', {
        visible: state === 'active',
        invisible: state === 'active-hidden',
      })}
    >
      <WebrtcPlayer url={livestreamUrl} />
      <div className="absolute top-2 left-2 rounded bg-black/50 px-2 py-1 text-xs font-semibold text-white">
        {cameraName}
      </div>
      {!hideActions && (
        <div className="absolute top-1/2 left-1/2 hidden -translate-x-1/2 -translate-y-1/2 group-hover/feed:block">
          <div className="flex gap-2">
            <FeedActionButton text="Safe" onClick={onClickSafe} />
            <FeedActionButton text="Snooze" onClick={onClickSnooze} />
            <FeedActionButton text="Escalate" onClick={onClickEscalate} />
          </div>
        </div>
      )}
    </div>
  );
};

interface FeedActionButtonProps {
  text: string;
  onClick: () => void;
}

function FeedActionButton(props: FeedActionButtonProps) {
  const { text, onClick } = props;

  return (
    <button
      onClick={onClick}
      className="rounded bg-black/50 px-3 py-1 text-sm font-semibold text-white transition-colors hover:bg-black/80"
    >
      {text}
    </button>
  );
}

export default LiveCameraFeed;
